<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-form
        v-on:keyup.enter="fetchList"
        class="m-2"
      >
        <!-- Filters -->
        <b-row>
          <b-col
            lg="12"
            md="12"
          >
            <b-row>
              <!-- Name filter -->
              <b-col
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="filters.name"
                    name="name"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

              <!-- Company name filter -->
              <b-col
                v-if="hasAccessToAll"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Company"
                  label-for="company"
                >
                  <vue-autosuggest
                    v-model="filters.companyName"
                    :suggestions="suggestionList"
                    :limit="10"
                    :input-props="{id:'company', class:'form-control', placeholder:'Company name'}"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item }}</span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>

              <!-- Country filter -->
              <b-col
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <b-form-input
                    id="country"
                    v-model="filters.country"
                    name="country"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Filter button -->
        <b-row class="mt-2">
          <b-col
            lg="3"
            md="3"
          >
            <b-button
              variant="primary"
              @click="fetchList"
            >
              <span class="text-nowrap">Filter</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!-- Add new -->
    <b-row class="mt-2">
      <b-col
        lg="3"
        md="3"
      >
        <b-button
          variant="outline-primary"
          @click="$router.push({ name: 'add-warehouses' })"
        >
          <span class="text-nowrap">Add new</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Content -->
    <b-card
      no-body
      class="mt-2"
    >

      <b-card-actions
        ref="loadingCard"
        no-actions
      >
        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="dataList"
            :fields="tableColumns"
            stacked="sm"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
        >

          <!-- Column: Created at -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-col>
              <b-button-group>
                <b-button
                  v-if="$permissions().hasPermission('edit warehouse')"
                    variant="primary"
                    size="sm"
                    @click="edit(data.item.id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline"
                  />
                </b-button>
                <b-button
                  v-if="$permissions().hasPermission('delete warehouse')"
                  v-b-tooltip.hover.top="'Delete warehouse'"
                  variant="outline-danger"
                  size="sm"
                  @click="deleteItem(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline"
                  />
                </b-button>
              </b-button-group>
            </b-col>
          </template>

        </b-table>
      </b-card-actions>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, VBTooltip, BFormInput, BFormGroup, BButtonGroup, BForm,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import handleError from '@/views/components/errorHandler'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BForm,
    VueAutosuggest,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormInput,
    BFormGroup,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      companySuggestions: [
        { data: [] },
      ],
      companyList: [],
      filters: {
        name: null,
        companyName: null,
        country: null,
      },
      dataList: [],
    }
  },
  computed: {
    suggestionList() {
      if (this.filters.companyName === '' || this.filters.companyName === undefined || this.filters.companyName === null) {
        return []
      }
      const filtered = []
      this.companyList.forEach(item => {
        if (item !== null && item.toLowerCase().indexOf(this.filters.companyName.toLowerCase()) > -1) {
          filtered.push(item)
        }
      })

      return [{
        data: filtered,
      }]
    },
    hasAccessToAll() {
      return this.$permissions().hasPermission('view all warehouses')
    },
    tableColumns() {
      const result = []
      result.push({ key: 'name', label: 'Name', sortable: true })
      result.push({
        key: 'code',
        label: 'Code',
        sortable: true,
        thClass: 'd-none d-sm-table-cell',
        tdClass: 'd-none d-sm-table-cell',
      })
      result.push({ key: 'country', label: 'Country', sortable: true })
      if (this.hasAccessToAll) {
        result.push({ key: 'company_name', label: 'Company', sortable: true })
      }
      result.push({ key: 'created_at', sortable: true, thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell' })
      if (
        this.$permissions().hasPermission('edit warehouse')
        || this.$permissions().hasPermission('delete warehouse')
      ) {
        result.push({ key: 'actions' })
      }
      return result
    },
  },
  mounted() {
    this.fetchList()
    if (this.hasAccessToAll) {
      this.fetchCompanyList()
    }
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'edit-warehouses', params: { warehouseId: id } })
    },
    deleteItem(id) {
      this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/warehouses/${id}`)
        .then(() => {
          this.$router.push({ name: 'warehouses' })
          this.fetchList()
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchCompanyList() {
      this.$http.get('/v1/companies')
        .then(response => {
          const data = []
          response.data.data.forEach(value => {
            data.push(value.name)
          })
          this.$data.companyList = data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchList() {
      // Construct filters
      const filters = []
      if (this.filters.name !== null) {
        filters.push(`name=${this.filters.name}`)
      }
      if (this.filters.companyName !== null) {
        filters.push(`company_name=${this.filters.companyName}`)
      }
      if (this.filters.country !== null) {
        filters.push(`country=${this.filters.country}`)
      }
      const textFilters = filters.join('&')

      // Request
      this.$refs.loadingCard.showLoading = true
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/warehouses?${textFilters}`)
        .then(response => {
          this.$refs.loadingCard.showLoading = false
          this.$data.dataList = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
